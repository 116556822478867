import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { Link } from "gatsby";

const PageNotFoundPage = () => {
	return (
		<Layout indication='hcp' className='hcp-page-not-found'>
			<Seo pageTitle='Page Not Found' />
			<div className='body-content-container'>
				<div className='body-content'>
					<section>
						<Row align='center'>
							<Col xs={12}>
								<h1 className='h1--condensed'>Page Not Found</h1>
                <p>Sorry 😔, we couldn’t find what you were looking for. Please check the <Link to="/site-map">sitemap</Link>.</p>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default PageNotFoundPage;
